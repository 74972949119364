import React from 'react'
import { alpha, styled } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Switch, IconButton, Avatar, Button, Badge } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { formatPhoneNumber } from './ChatConversationItem';

// redux
import { useDispatch, useSelector } from '../../../redux/store';
import MyAvatar from 'src/components/MyAvatar';

const ChatProfileView = ({getInitials, onCLickEditHandler}) => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
    const { contacts, recipients, participants, activeConversationId } = useSelector((state) => state.chat);
    const avatarName = getInitials(activeConversationId?.customerInfo?.customer_name);
   
  return (
    <div>
       <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', '&:hover .edit-icon': { visibility: 'visible' }, mb:2}}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
        {activeConversationId?.customerInfo?.customer_name?<Avatar sx={{ bgcolor: alpha('#000', 0.1) }}>{avatarName?avatarName:""}</Avatar>: <MyAvatar />}
        </Badge>
        <Typography variant="h6">{activeConversationId?.customerInfo?.customer_name ? activeConversationId?.customerInfo?.customer_name : formatPhoneNumber(activeConversationId?.customerPhone)}</Typography>
      </Stack>
      <EditIcon
          className="edit-icon"
          sx={{
            visibility: 'hidden',
            color: 'gray',
            cursor: 'pointer',
            opacity: 1,
            fontSize: '17px',
          }}
          onClick = {onCLickEditHandler}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', '&:hover .edit-icon': { visibility: 'visible' } }}>
        <Typography variant="body2" color="text.secondary">
          Marketing Opt-In
        </Typography>
        <EditIcon
          className="edit-icon"
          sx={{
            visibility: 'hidden',
            color: 'gray',
            cursor: 'pointer',
            opacity: 1,
            fontSize: '17px',
          }}
          onClick = {onCLickEditHandler}
        />
      </Box>
      <Typography variant="body1">{activeConversationId?.customerInfo?.market_opt_in?'yes':'no' }</Typography>

      <Divider sx={{ my: 2 }} />

      <Stack spacing={1}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', '&:hover .edit-icon': { visibility: 'visible' } }}>
        <Typography variant="body2" color="text.secondary">
          Phone Number
        </Typography>
        <EditIcon
          className="edit-icon"
          sx={{
            visibility: 'hidden',
            color: 'gray',
            cursor: 'pointer',
            opacity: 1,
            fontSize: '17px',
          }}
          onClick = {onCLickEditHandler}
        />
        </Box>
        <Typography variant="body1">{formatPhoneNumber(activeConversationId?.customerPhone)}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', '&:hover .edit-icon': { visibility: 'visible' } }}>
        <Typography variant="body2" color="text.secondary">
          Email
        </Typography>
        <EditIcon
          className="edit-icon"
          sx={{
            visibility: 'hidden',
            color: 'gray',
            cursor: 'pointer',
            opacity: 1,
            fontSize: '17px',
          }}
          onClick = {onCLickEditHandler}
        />
        </Box>
        <Typography variant="body1">{activeConversationId?.customerInfo?.customer_email?activeConversationId?.customerInfo?.customer_email:'-'}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', '&:hover .edit-icon': { visibility: 'visible' } }}>
        <Typography variant="body2" color="text.secondary">
          Handle
        </Typography>
        <EditIcon
          className="edit-icon"
          sx={{
            visibility: 'hidden',
            color: 'gray',
            cursor: 'pointer',
            opacity: 1,
            fontSize: '17px',
          }}
          onClick = {onCLickEditHandler}
        />
        </Box>
        <Typography variant="body1">{activeConversationId?.customerInfo?.customer_handle?activeConversationId?.customerInfo?.customer_handle:'-'}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', '&:hover .edit-icon': { visibility: 'visible' } }}>
        <Typography variant="body2" color="text.secondary">
          Department
        </Typography>
        <EditIcon
          className="edit-icon"
          sx={{
            visibility: 'hidden',
            color: 'gray',
            cursor: 'pointer',
            opacity: 1,
            fontSize: '17px',
          }}
          onClick = {onCLickEditHandler}
        />
        </Box>
        <Typography variant="body1">{activeConversationId?.customerInfo?.department?.name?activeConversationId?.customerInfo?.department?.name:'-'}</Typography>
      </Stack>

      <Divider sx={{ my: 2 }} />

      <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', '&:hover .edit-icon': { visibility: 'visible' } }}>
        <Typography variant="subtitle1">Groups</Typography>
        <EditIcon
          className="edit-icon"
          sx={{
            visibility: 'hidden',
            color: 'gray',
            cursor: 'pointer',
            opacity: 1,
            fontSize: '17px',
          }}
          onClick = {onCLickEditHandler}
        />
      </Box>
      <Typography variant="body2" color="primary">
          -
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography variant="subtitle1">Additional Details</Typography>
        {activeConversationId?.customerInfo?.tags===null? <Stack spacing={1} mt={1}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', '&:hover .edit-icon': { visibility: 'visible' } }}>
          <Typography variant="body2" color="text.secondary">
            Lead Stage
          </Typography>
          <EditIcon
          className="edit-icon"
          sx={{
            visibility: 'hidden',
            color: 'gray',
            cursor: 'pointer',
            opacity: 1,
            fontSize: '17px',
          }}
          onClick = {onCLickEditHandler}
        />
          </Box>
          <Typography variant="body1">-</Typography>
          <Typography variant="body2" color="text.secondary">
            Appointment Date
          </Typography>
          <Typography variant="body1">-</Typography>
          <Typography variant="body2" color="text.secondary">
            Preferred Language
          </Typography>
          <Typography variant="body1">-</Typography>
        </Stack>:'-'}
      </Box>
    </div>
  )
}

export default ChatProfileView
