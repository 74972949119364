import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui

import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem } from '@mui/material';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import CustomConfirmBox from 'src/components/CustomConfirmBox';

ChannelTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function ChannelTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const theme = useTheme();

  const { name, avatarUrl, company_handle, verified, status, created_at } = row;

  const departmentType = row.channel.name;
  const lastCommunication = row.channel.updated_at;

  const [openMenu, setOpenMenuActions] = useState(null);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const handDialogBox = () => {
    setOpenDialogBox(!openDialogBox);
  };
  const handleDialogBoxYes = () => {
    onDeleteRow();
  };
  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  
  return (
    <>
      <CustomConfirmBox
        open={openDialogBox}
        handleClose={handDialogBox}
        title={'Are you sure you want to delete ?'}
        handleCloseYes={handleDialogBoxYes}
      />
      <TableRow hover selected={selected}>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} />
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </TableCell>

        <TableCell align="center">{company_handle}</TableCell>

        <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
          {departmentType}
        </TableCell>

        <TableCell align="center">
          <Iconify
            icon={verified ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
            sx={{
              width: 20,
              height: 20,
              color: 'success.main',
              ...(!verified && { color: 'warning.main' }),
            }}
          />
        </TableCell>

        <TableCell align="center">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={(status === 'banned' && 'error') || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            Yes
          </Label>
        </TableCell>

        <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
          {new Date().toLocaleDateString(lastCommunication)}
        </TableCell>

        <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
          {status}
        </TableCell>

        <TableCell align="center">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    handDialogBox();
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  Delete
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onEditRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>
              </>
            }
          />
        </TableCell>
      </TableRow>
    </>
  );
}
