import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  TextField,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreateContactSubmodal from './CreateContacSubmodal';

const contacts = [
  { name: 'ravi kumar', number: '+91 64758 92747' },
  { name: 'sonali', number: '+91 5676 543 234' },
  { name: 'pranav', number: '+91 98375 47293' },
  { name: 'neeraj', number: '+91 98767 98765' },
//   { name: 'nikhil', number: '+91 3456 789 876' },
//   { name: 'rajesh', number: '+91 85076 57892' },
//   { name: 'richa', number: '+91 3456 787 654' },
//   { name: 'vanshika', number: '+91 67890 34456' },
//   { name: 'ria', number: '+91 67889 04556' },
//   { name: 'Shreeeaa', number: '+91 98509 39495' },
//   { name: 'Shreea Sri', number: '+91 99715 19545' },
];

const CreateContactModal = ({ open, handleClose }) => {
  const [selectedNumber, setSelectedNumber] = useState('Demo number');
  const [contactSubmodalOpen, setContactsubModalOpen] = useState(false);

    const handleSubModalOpen = () => setContactsubModalOpen(true);
    const handleSubModalClose = () => setContactsubModalOpen(false);

  const handleChange = (event) => {
    setSelectedNumber(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="contact-modal-title"
      aria-describedby="contact-modal-description"
    >
      <Box
        sx={{
            position: 'fixed', 
            top: '10%',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 510,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            maxHeight: '80vh',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            padding:3.5
        }}
      >
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', height: '6vh' }}>
          <Typography id="contact-modal-title" variant="h6" component="h2">
            Initiate new conversation on
          </Typography>

          <Select
            size="small"
            value={selectedNumber}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            sx={{ mt: 2, mb: 2, width: 170, height: '4vh' }}
          >
            <MenuItem value="Demo number">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                <Box sx={{ mt: '5px' }}>
                  <svg
                    stroke="currentColor"
                    fill="#22c35e"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    focusable="false"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M260.062 32C138.605 32 40.134 129.701 40.134 250.232c0 41.23 11.532 79.79 31.559 112.687L32 480l121.764-38.682c31.508 17.285 67.745 27.146 106.298 27.146C381.535 468.464 480 370.749 480 250.232 480 129.701 381.535 32 260.062 32zm109.362 301.11c-5.174 12.827-28.574 24.533-38.899 25.072-10.314.547-10.608 7.994-66.84-16.434-56.225-24.434-90.052-83.844-92.719-87.67-2.669-3.812-21.78-31.047-20.749-58.455 1.038-27.413 16.047-40.346 21.404-45.725 5.351-5.387 11.486-6.352 15.232-6.413 4.428-.072 7.296-.132 10.573-.011 3.274.124 8.192-.685 12.45 10.639 4.256 11.323 14.443 39.153 15.746 41.989 1.302 2.839 2.108 6.126.102 9.771-2.012 3.653-3.042 5.935-5.961 9.083-2.935 3.148-6.174 7.042-8.792 9.449-2.92 2.665-5.97 5.572-2.9 11.269 3.068 5.693 13.653 24.356 29.779 39.736 20.725 19.771 38.598 26.329 44.098 29.317 5.515 3.004 8.806 2.67 12.226-.929 3.404-3.599 14.639-15.746 18.596-21.169 3.955-5.438 7.661-4.373 12.742-2.329 5.078 2.052 32.157 16.556 37.673 19.551 5.51 2.989 9.193 4.529 10.51 6.9 1.317 2.38.901 13.531-4.271 26.359z"></path>
                  </svg>
                </Box>
                <box> Demo Number</box>
              </Box>
            </MenuItem>
          </Select>

          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField size="small" placeholder="Search" variant="outlined" fullWidth sx={{ mt: 0.5, mb: 1 }} />
        <div>
        <Button
          variant=""
          sx={{
            color: '#2b6cb0',
            pl:0,
            gap: 1.3,
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
            justifyContent:'left',
            width:170
          }}
          onClick={handleSubModalOpen}
        >
          <box>
            <Avatar sx={{ backgroundColor: '#EDF2F7' }}>
              <svg
                stroke="currentColor"
                fill="#2b6cb0"
                stroke-width="0"
                version="1.2"
                baseProfile="tiny"
                viewBox="0 0 24 24"
                font-size="18px"
                role="img"
                aria-label=" avatar"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9 14c1.381 0 2.631-.56 3.536-1.465.904-.904 1.464-2.154 1.464-3.535s-.56-2.631-1.464-3.535c-.905-.905-2.155-1.465-3.536-1.465s-2.631.56-3.536 1.465c-.904.904-1.464 2.154-1.464 3.535s.56 2.631 1.464 3.535c.905.905 2.155 1.465 3.536 1.465zM9 21c3.518 0 6-1 6-2 0-2-2.354-4-6-4-3.75 0-6 2-6 4 0 1 2.25 2 6 2zM21 12h-2v-2c0-.553-.447-1-1-1s-1 .447-1 1v2h-2c-.553 0-1 .447-1 1s.447 1 1 1h2v2c0 .553.447 1 1 1s1-.447 1-1v-2h2c.553 0 1-.447 1-1s-.447-1-1-1z"></path>
              </svg>
            </Avatar>
          </box>
          <box>Create contact</box>
        </Button>
        <CreateContactSubmodal open={contactSubmodalOpen} handleClose={handleSubModalClose} />
        </div>
        <List sx={{ maxHeight: 600, overflow: 'auto' }}>
          {contacts.map((contact, index) => (
            <ListItem button key={index} sx={{
                pl: 0,
              }}>
              <ListItemAvatar sx={{ml:'0px',mr:'12px'}}>
                <Avatar>{contact.name.charAt(0).toUpperCase()}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={contact.name} secondary={contact.number} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export default CreateContactModal;
