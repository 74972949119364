import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, MenuItem, Tooltip, Button } from '@mui/material';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import CustomConfirmBox from 'src/components/CustomConfirmBox';
import CampaignModels from './CampaignModels';

// ----------------------------------------------------------------------

CampaignTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function CampaignTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const theme = useTheme();

  const { id, name, description, template, groups, segments, status, organisation_departments } = row;
  const templateName = template?.name || 'N/A';
  const fetchedGroups = Object.values(groups).map((groups) => groups.name);
  const fetchedSegments = Object.values(segments).map((segments) => segments.name);
  const fetchedDep = Object.values(organisation_departments).map(
    (organisation_departments) => organisation_departments.name
  );

  const [openMenu, setOpenMenuActions] = useState(null);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const handleViewClick = () => {
    setOpenGroupModal(true);
  };

  const handleGrpModalClose = () => {
    setOpenGroupModal(false);
  };

  const [openSegmentModal, setOpenSegmentModal] = useState(false);
  const handleSegmentViewClick = () => {
    setOpenSegmentModal(true);
  };

  const handleSegmentModalClose = () => {
    setOpenSegmentModal(false);
  };

  const [openDeptModal, setOpenDeptModal] = useState(false);
  const handleDeptViewClick = () => {
    setOpenDeptModal(true);
  };

  const handleDeptModalClose = () => {
    setOpenDeptModal(false);
  };

  const handDialogBox = () => {
    setOpenDialogBox(!openDialogBox);
  };
  const handleDialogBoxYes = () => {
    onDeleteRow();
  };
  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <>
      {openDialogBox && (
        <CustomConfirmBox
          open={openDialogBox}
          handleClose={handDialogBox}
          title={'Are you sure you want to delete ?'}
          handleCloseYes={handleDialogBoxYes}
        />
      )}
      <CampaignModels
        open={openGroupModal}
        handleClose={handleGrpModalClose}
        data={row}
        fetchedData={fetchedGroups}
        type="groups"
      />
      <CampaignModels
        open={openSegmentModal}
        handleClose={handleSegmentModalClose}
        data={row}
        fetchedData={fetchedSegments}
        type="segments"
      />
      <CampaignModels
        open={openDeptModal}
        handleClose={handleDeptModalClose}
        data={row}
        fetchedData={fetchedDep}
        type="departments"
      />
      <TableRow hover selected={selected}>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {id}
          </Typography>
        </TableCell>

        <TableCell align="center">{name}</TableCell>

        <TableCell align="center">{description}</TableCell>

        <TableCell align="center">{templateName}</TableCell>

        <TableCell align="center">
          {/* {fetchedGroups.length > 0 ? topThreeGroups.join(', ') : 'No Groups available'} */}
          {fetchedGroups.length > 0 ? <Button onClick={handleViewClick}>View</Button> : '-'}{' '}
        </TableCell>

        <TableCell align="center">
          {fetchedSegments.length > 0 ? <Button onClick={handleSegmentViewClick}>View</Button> : '-'}
        </TableCell>

        <TableCell align="center">{status}</TableCell>

        <TableCell align="center">
          {fetchedDep.length > 0 ? <Button onClick={handleDeptViewClick}>View</Button> : '-'}
        </TableCell>

        <TableCell align="center">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    handDialogBox();
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  Delete
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onEditRow(row);
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>
              </>
            }
          />
        </TableCell>
      </TableRow>
    </>
  );
}
