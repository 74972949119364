import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import {
  Stack,
  Input,
  Divider,
  IconButton,
  InputAdornment,
  Typography,
  Popover,
  Button,
  CircularProgress,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import CollectionsIcon from '@mui/icons-material/Collections';
import Iconify from '../../../components/Iconify';
import EmojiPicker from '../../../components/EmojiPicker';
import { useSelector } from 'src/redux/store';
import DocIcon from '../../../assets/doc.png';
import axios from '../../../utils/axios';
import { ApiEndpoints } from 'src/config';

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 56,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
}));

const FilePreview = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1.5),
}));

export const formatBytes = (bytes) => {
  if (bytes === 0) return '0 Byte';
  const k = 1024;
  const dm = 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getMimeType = (mimeType) => {
  const mimeTypeMap = {
    'image/jpeg': 'JPEG',
    'image/png': 'PNG',
    'image/gif': 'GIF',
    'application/pdf': 'PDF',
    'application/msword': 'DOC',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
    'text/csv': 'CSV',
  };

  return mimeTypeMap[mimeType] || mimeType;
};

ChatMessageInput.propTypes = {
  disabled: PropTypes.bool,
  conversationId: PropTypes.string,
  onSend: PropTypes.func,
};

export default function ChatMessageInput({
  disabled,
  conversationId,
  onSend,
  sendMessage,
  fileData,
  setFileData,
  filePreview,
  setFilePreview,
  uploadData,
  setUploadData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState('');
  const [captionMessage, setCaptionMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const toUser = useSelector((state) => state.chat.activeConversationId);
  const userData = useSelector((state) => state?.auth?.user?.data);
  const userRole = userData?.user?.user_type;
  const userId = userData?.user?.native_id;


  const handleAttach = (event) => {
    fileInputRef.current?.click();
    setAnchorEl(event.currentTarget);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const uploadFile = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await axios.post(`${ApiEndpoints.AGENT_DOC_UPLOAD}?file_type=WHATSAPP_MEDIA`, formData);
      if(response?.status === 200){
      setUploadData(response?.data?.data);
      enqueueSnackbar("Uploaded successfully.");
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      enqueueSnackbar('Error uploading file:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    console.log('chngein file',event)
    const file = event.target.files[0];
    if (file) {
      setFileData(file);
      if (file.type.startsWith('image/')) {
        const fileUrl = URL.createObjectURL(file);
        setFilePreview(fileUrl);
      } else {
        setFilePreview('');
      }
      uploadFile(file);
    }
  };

  const handleSend = async () => {
    if (!message && !fileData) {
      return;
    }


    if (sendMessage && conversationId) {
      if (fileData) {
        sendMessage(captionMessage, userId, toUser?.customerPhone, fileData, uploadData);
      } else {
        sendMessage(message, userId, toUser?.customerPhone);
      }
    }

    setMessage('');
    setFileData(null);
    setFilePreview('');
    setUploadData(null);
    setCaptionMessage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setAnchorEl(null);
  };

  const handleClosePopoverManually = () => {
    setFileData(null);
    setFilePreview('');
    setAnchorEl(null);
    setUploadData(null);
    setCaptionMessage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'file-popover' : undefined;

  return (
    <RootStyle>
      <Input
        disabled={disabled}
        fullWidth
        value={message}
        disableUnderline
        onKeyUp={handleKeyUp}
        onChange={(event) => setMessage(event.target.value)}
        placeholder="Type a message"
        startAdornment={
          <InputAdornment position="start">
            <EmojiPicker disabled={disabled} value={message} setValue={setMessage} />
          </InputAdornment>
        }
        endAdornment={
          <Stack direction="row" spacing={1} sx={{ flexShrink: 0, mr: 1.5 }}>
            <IconButton disabled={disabled} size="small" onClick={handleAttach}>
              <Iconify icon="ic:round-add-photo-alternate" width={22} height={22} />
            </IconButton>
            <IconButton disabled={disabled} size="small" onClick={handleAttach}>
              <Iconify icon="eva:attach-2-fill" width={22} height={22} />
            </IconButton>
            <IconButton disabled={disabled} size="small">
              <Iconify icon="eva:mic-fill" width={22} height={22} />
            </IconButton>
          </Stack>
        }
      />

      <Divider orientation="vertical" flexItem />

      <IconButton color="primary" disabled={!message && !fileData} onClick={handleSend} sx={{ mx: 1 }}>
        <Iconify icon="ic:round-send" width={22} height={22} />
      </IconButton>

      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />

      {fileData && open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          disableEnforceFocus
          disableAutoFocus
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{
            width: 600,
            height: 400,
            mt: 6,
            ml: 12,
          }}
        >
          <Stack
            sx={{
              p: 2,
              width: 500,
              height: 300,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                width: '100%',
                height: '100%',
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  {uploadData ? ( 
                    <>
                      {filePreview ? (
                        <CollectionsIcon sx={{ fontSize: 100 }} />
                      ) : getMimeType(fileData.type) === 'PDF' ? (
                        <PictureAsPdfIcon sx={{ fontSize: 100 }} />
                      ) : getMimeType(fileData.type) === 'DOCX' ? (
                        <img src={DocIcon} alt="docicon" style={{ width: '60px', height: '60px' }} />
                      ) : (
                        <ArticleIcon sx={{ fontSize: 100 }} />
                      )}
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {`${getMimeType(fileData.type)}-${fileData.name}`}
                      </Typography>
                      <Typography variant="body2">{formatBytes(fileData.size)}</Typography>
                      <Typography variant="body2">{getMimeType(fileData.type)}</Typography>
                    </>
                  ) : (
                    <Typography variant="body2" sx={{ color: 'red', mt: 2 }}>
                      File size exceeds the allowed limit.
                    </Typography>
                  )}
                </>
              )}
            </Stack>

            <Button
              onClick={handleClosePopoverManually}
              variant="text"
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
              }}
            >
              Close
            </Button>

            <Stack
              direction="row"
              alignItems="center"
              sx={{
                width: '100%',
                gap: 1,
                borderTop: 0.2,
              }}
            >
              <Input
                disabled={disabled}
                fullWidth
                value={captionMessage}
                disableUnderline
                onKeyUp={handleKeyUp}
                onChange={(event) => setCaptionMessage(event.target.value)}
                placeholder="Add a caption..."
                sx={{ flex: 1, borderRight: 0.2 }}
                startAdornment={
                  <InputAdornment position="start">
                    <EmojiPicker disabled={disabled} value={captionMessage} setValue={setCaptionMessage} />
                  </InputAdornment>
                }
              />
              <IconButton color="primary" disabled={!uploadData} onClick={handleSend}>
                <Iconify icon="ic:round-send" width={22} height={22} />
              </IconButton>
            </Stack>
          </Stack>
        </Popover>
      )}
    </RootStyle>
  );
}
