import React, { useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

// redux
import ChatProfileView from './ChatProfileView';
import ChatProfileEdit from './ChatProfileEdit';

const ContactCard = () => {

  const [isEdit, setIsEdit] = useState(false); 
  const getInitials = (name) => {
    if (!name?.trim()) {
      return ''; 
    }
    
    const words = name.trim().split(' '); 
    if (words.length === 1) {
      return words[0][0]?.toUpperCase(); 
    }
  
    return (words[0][0] + words[words.length - 1][0]).toUpperCase(); 
  };

  const onCLickEditHandler =()=>{ 
    console.log("editHandlerClick")
    setIsEdit(!isEdit)
}

  return (
    <Box
      sx={{
        p: 3,
        border: `1px solid ${alpha('#000', 0.12)}`,
        borderRadius: 2,
        width: '25vw',
        maxWidth: '25vw',
        maxHeight: '100vh',
        overflow: 'auto',
        backgroundColor:'white'
      }}
    >
      <div>
        {!isEdit?<ChatProfileView getInitials={getInitials} onCLickEditHandler={onCLickEditHandler} />:<ChatProfileEdit onCLickEditHandler={onCLickEditHandler}  setIsEdit={setIsEdit}/>}
      </div>
    </Box>
  );
};

export default ContactCard;
