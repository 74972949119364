import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Autocomplete,
  Chip,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  MenuItem,
  Divider,
  FormHelperText,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
  Button,
  Icon,
  OutlinedInput,
} from '@mui/material';
import { ApiEndpoints, DATE_FORMATS } from '../../../config';
import { PATH_APP } from '../../../routes/paths';
import { countries } from '../../../_mock';

import {
  FormProvider,
  RHFRadioGroup,
  RHFSelect,
  RHFTextArea,
  RHFTextField,
  RHFUploadSingleFile,
  RHFDateField,
} from '../../../components/hook-form';
import axios from '../../../utils/axios';
import moment from 'moment';
import axiosInstance from '../../../utils/axios';
import RHFCustomDatePicker from 'src/components/hook-form/RHFCustomDatePicker';
// import { InfoIcon } from 'src/theme/overrides/CustomIcons';
// import SvgIconStyle from 'src/components/SvgIconStyle';
import { InfoOutlined } from '@mui/icons-material';
// import { template } from 'lodash';
// import { frequently } from 'emoji-mart';
// import { isDebuggerStatement } from 'typescript';
// import { group } from 'd3-array';

export default function AddNewCampaign({ isEdit, currentUser, fetchDepartments, toggleDrawer }) {
  const navigate = useNavigate();
  const editCase = typeof isEdit === 'object';
  const { enqueueSnackbar } = useSnackbar();
  const [usersFileCsv, setUsersFileCsv] = useState('');
  // const [imagesFileData, setImagesFileData] = useState('');
  const [uploadMessage, setUploadMessage] = useState(false);
  const [uploadedFilename, setUploadedFilename] = useState('');

  const [departments, setDepartments] = useState([]);

  const [groups, setCrmGroups] = useState([]);

  const [Segments, setCrmSegments] = useState([]);

  const [waTemplates, setWaTemplates] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchAllDepartments = async () => {
    setLoading(true);
    let url = `${ApiEndpoints.DEPARTMENTS}`;
    try {
      const response = await axios.get(url);
      if (response?.data?.status == 200) {
        setDepartments(response?.data?.data);
      }
    } catch (error) {
      console.error('Failed to fetch departments', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Group List
  const fetchGroupList = async () => {
    setLoading(true);
    let url = `${ApiEndpoints.CRM_GROUP_LIST}`;
    try {
      const response = await axios.get(url);
      if (response?.data?.status == 200) {
        setCrmGroups(response?.data?.data?.data);
      }
    } catch (error) {
      console.error('Failed to fetch group list', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Segment List
  const fetchSegmentList = async () => {
    setLoading(true);
    let url = `${ApiEndpoints.CRM_SEGMENT_LIST}`;
    try {
      const response = await axios.get(url);
      if (response?.data?.status == 200) {
        setCrmSegments(response?.data?.data?.data);
      }
    } catch (error) {
      console.error('Failed to fetch segment list', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch WhatsApp Templates
  const fetchWhatsappTemplates = async () => {
    setLoading(true);
    let url = `${ApiEndpoints.WA_TEMPLATE_CREATE}`;
    try {
      const response = await axios.get(url);
      if (response?.data?.status == 200) {
        setWaTemplates(response?.data?.data);
      }
    } catch (error) {
      console.error('Failed to fetch WhatsApp templates', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllDepartments();
    fetchGroupList();
    fetchSegmentList();
    fetchWhatsappTemplates();
  }, []);

  const NewUserSchema = Yup.object().shape({
    campaignName: Yup.string().required('Campaign Name is required'),
    description: Yup.string().required('Description is required'),
    template: Yup.string().required('Please select a template'),
    status: Yup.string().required('Please select Status'),
    department: Yup.array().required('Department is required').min(1, 'Select atleast one department'),
    users: Yup.string().required('Please select either group/segmen of campaign'),
    group: Yup.array().when('users', {
      is: '1',
      then: Yup.array().required('Please select at least one group').min(1, 'Select atleast 1 group'),
    }),
    segment: Yup.array().when('users', {
      is: '2',
      then: Yup.array().required('Please select at least one segment').min(1, 'Select atleast 1 segment'),
    }),
    schedule: Yup.string().required('Schedule Required'),
    isReccuring: Yup.boolean(),
    frequency: Yup.number().when('isReccuring', {
      is: true,
      then: Yup.number().required('Frequency is required').min(1, 'Frequency must be at least 1'),
    }),
    startDate: Yup.date()
      .when('schedule', {
        is: '2',
        then: Yup.date()
          .required('Start date is required')
          .min(new Date(), 'Start date must be in the future or the current time'),
      })
      .nullable(),
    endDate: Yup.date()
      .when('schedule', {
        is: '2',
        then: Yup.date()
          .required('End date is required')
          .test('is-greater', 'End date must be at least 30 minutes after start date', function (value) {
            const { startDate } = this.parent;
            if (startDate && value) {
              return moment(value).isSameOrAfter(moment(startDate).add(30, 'minutes'));
            }
            return true;
          }),
      })
      .nullable(),
  });
  const handleCheckboxChange = (event) => {
    setValue('isReccuring', event.target.checked);
  };
  const handleCSVCheckboxChange = (event) => {
    setValue('checkCSV', event.target.checked);
  };
  const handleInputChange = (event) => {
    setValue('frequency', event.target.value);
  };

  const defaultValues = {
    campaignName: editCase && isEdit ? isEdit.name : '',
    description: editCase && isEdit ? isEdit?.description : '',
    template: editCase && isEdit ? isEdit.template?.id : '',
    department: editCase && isEdit ? isEdit.organisation_department_ids : [],
    group: editCase && isEdit ? isEdit.group_ids : [],
    segment: editCase && isEdit ? isEdit.segment_ids : [],
    status: editCase && isEdit ? isEdit.status : '',
    schedule: editCase && isEdit ? '2' : '',
    isReccuring: editCase && isEdit ? isEdit.is_recurring : false,
    frequency: editCase && isEdit ? (isEdit.is_recurring ? isEdit.frequency : 1) : 1,
    startDate: editCase && isEdit ? moment(isEdit?.startDate).format('YYYY-MM-DDTHH:mm:ss.SSS') : new Date(),
    endDate: editCase && isEdit ? moment(isEdit?.endDate).format('YYYY-MM-DDTHH:mm:ss.SSS') : new Date(),
    users: editCase && isEdit ? (isEdit?.group_ids?.length > 0 ? '1' : isEdit?.segment_ids?.length > 0 ? '2' : '') : '',
  };
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
  } = methods;
  const values = watch();

  useEffect(() => {
    if (isEdit) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, departments]);

  const onSubmit = async (data) => {
    const fileUrl = usersFileCsv;
    const payload = {
      customers_file_url: fileUrl,
      whats_app_template_id: data?.template,
      organisation_department_ids: data?.department,
      group_ids: data?.group,
      segment_ids: data?.segment,
      is_recurring: data?.isReccuring,
      status: data?.status,
      frequency: data?.isReccuring ? data?.frequency : null,
      description: data?.description,
      end_date:
        data?.schedule == '1' && !data?.isReccuring
          ? moment().add(2, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS')
          : data?.schedule == '1' && data?.isReccuring
          ? moment(data?.endDate).format('YYYY-MM-DDTHH:mm:ss.SSS')
          : moment(data?.endDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),

      name: data?.campaignName,
      schedule_option: data?.schedule,
      start_date:
        data?.schedule == '1'
          ? moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
          : moment(data?.startDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    };
    const formData = new FormData();
    formData.append('campaignForm', JSON.stringify(payload));
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    await axiosInstance
      .post(editCase ? `${ApiEndpoints.UPDATECAMPAIGN}/${isEdit?.id}` : `${ApiEndpoints.ADDCAMPAIGN}`, payload)
      .then((response) => {
        if (response?.data?.status === 200) {
          reset();
          enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!', { variant: 'success' });
          toggleDrawer();
          fetchDepartments();
        } else {
          enqueueSnackbar(response?.data?.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formValues = getValues();
  // console.log({ formValues, isEdit }, 'campaignFromValues==>');
  const handleDrop = useCallback(
    async (acceptedFiles, fieldName) => {
      const file = acceptedFiles[0];
      if (file) {
        let formData = new FormData();
        formData.append('file', file);
        const fileType = 'CAMPAIGN_CUSTOMERS';
        const url = `${ApiEndpoints.UPLOAD_FILES}?file_type=${fileType}`;

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        try {
          const response = await axiosInstance.post(url, formData, config);

          if (response?.data?.status === 200) {
            const fileUrl = response?.data?.data?.fileUrl;
            const fileName = response?.data?.data?.fileName;
            setValue(fieldName, file); 
            setUploadedFilename(fileName);
            setUsersFileCsv(fileUrl);
            setUploadMessage(true);
            enqueueSnackbar('File uploaded successfully', { variant: 'success' });
          }  else {
            enqueueSnackbar('File upload failed', { variant: 'error' });
          } 
        } catch (error) {
          enqueueSnackbar("An error occured while uploading", { variant: 'error' });
        }
      }
    },
    [setValue, enqueueSnackbar]
  );
  // const handleFileUpload = async (e, fieldName) => {
  //   const fileList = e;
  //   // const { name, value } = e.target;
  //   if (fileList.length > 0) {
  //     const file = fileList[0];
  //     let extension = file.name.split('.').pop();
  //     let fileSize = file.size / 1024 / 1024;
  //     let extList = ['png', 'svg', 'jpeg', 'jpg'];
  //     if (extList.includes(extension)) {
  //       if (fileSize <= 1) {
  //         let formData = new FormData();
  //         formData.append('file', file);
  //         const config = {
  //           headers: {
  //             'Content-Type': 'multipart/form-data',
  //           },
  //         };
  //         let uploadedResponse = await axiosInstance.post(
  //           ApiEndpoints.UPLOAD_FILES + `?fileName=${file?.path}`,
  //           formData,
  //           config
  //         );
  //         if (uploadedResponse?.data?.status == 200) {
  //           setValue(fieldName, file);
  //           setImagesFileData(uploadedResponse?.data?.data);
  //         } else {
  //           setValue(fieldName, null);
  //         }
  //       } else {
  //         enqueueSnackbar('Size Should be less than 1mb', { variant: 'error' });
  //       }
  //     } else {
  //       enqueueSnackbar('Invalid Extension', { variant: 'error' });
  //     }
  //   }
  // };
  const statusTypes = ['PAUSED', 'PENDING'];
  const handleDepartmentChange = (event) => {
    setValue('department', event.target.value);
  };
  const handleTemplateChange = (event) => {
    setValue('template', event.target.value);
  };

  const handleStatusChange = (event) => {
    setValue('status', event.target.value);
  };

  const handleGrpChange = (event) => {
    setValue('group', event.target.value);
  };
  const handleSegmentChange = (event) => {
    setValue('segment', event.target.value);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container width={500}>
        <Grid item md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography variant="h4">{!isEdit ? 'Add New  Campaign' : 'Save Changes'}</Typography>
              <RHFTextField name="campaignName" label="Campaign Name" />
              <RHFTextField name="description" label="Description" />
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="wa-template-select-label">WhatsApp Templates</InputLabel>
                  <Select
                    labelId="wa-template-select-label"
                    id="wa-template-select"
                    name="template"
                    label="WhatsApp Templates"
                    value={formValues?.template || ''}
                    onChange={(e) => handleTemplateChange(e)}
                  >
                    {waTemplates?.filter((item)=> item?.status !== "REJECTED" )?.map((template, i) => (
                      <MenuItem key={i} value={template.id}>
                        {template.name} {`(${template.category})`}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(errors?.template?.message)}>
                    {errors?.template?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="status-lable">Status</InputLabel>
                  <Select
                    labelId="status-lable"
                    id="select-status"
                    name="status"
                    label="Status"
                    value={formValues?.status || ''}
                    onChange={(e) => handleStatusChange(e)}
                  >
                    {statusTypes?.map((template, i) => (
                      <MenuItem key={i} value={template}>
                        {template}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(errors?.status?.message)}>{errors?.status?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="department-multi-select-label">Department</InputLabel>
                  <Select
                    labelId="department-multi-select-label"
                    id="department-multi-select"
                    multiple
                    name="department"
                    value={formValues?.department}
                    onChange={(e) => handleDepartmentChange(e)}
                    input={<OutlinedInput label="Department" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected?.map((value) => (
                          <Chip key={value} label={departments.find((department) => department.id === value)?.name} />
                        ))}
                      </Box>
                    )}
                  >
                    {departments?.map((department) => (
                      <MenuItem key={department.id} value={department.id}>
                        {department.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(errors?.department?.message)}>
                    {errors?.department?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              {isEdit ? (
                <></>
              ) : (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.checkCSV}
                        onChange={(e) => handleCSVCheckboxChange(e)}
                        name="checkedCSV"
                      />
                    }
                    label="Upload CSV file"
                    name="checkCSV"
                  />
                  {formValues.checkCSV &&
                    (uploadMessage ? (
                      <>File Uploaded: {uploadedFilename}</>
                    ) : (
                      <RHFUploadSingleFile
                        name="userCSV"
                        accept={'text/csv'}
                        onDrop={(file) => handleDrop(file, 'userCsv')}
                      />
                    ))}
                </>
              )}

              <RHFRadioGroup name={'users'} options={['1', '2']} getOptionLabel={['Groups', 'Segments']} row={true} />
              {formValues.users === '1' && (
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="group-select-label">Groups</InputLabel>
                    <Select
                      labelId="group-select-label"
                      id="group-select"
                      name="group"
                      label="Groups"
                      multiple
                      value={formValues?.group}
                      onChange={(e) => handleGrpChange(e)}
                      input={<OutlinedInput label="Groups" />}
                      error={errors?.group?.message}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={groups.find((group) => group.id === value)?.name} />
                          ))}
                        </Box>
                      )}
                    >
                      {groups?.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={Boolean(errors?.group?.message)}>{errors?.group?.message}</FormHelperText>
                  </FormControl>
                </Box>
              )}
              {formValues.users === '2' && (
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="segment-select-label">Segments</InputLabel>
                    <Select
                      labelId="segment-select-label"
                      id="segment-select"
                      name="segment"
                      label="Segments"
                      multiple
                      value={formValues?.segment}
                      onChange={(e) => handleSegmentChange(e)}
                      input={<OutlinedInput label="Segments" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected?.map((value) => (
                            <Chip key={value} label={Segments.find((segment) => segment.id === value)?.name} />
                          ))}
                        </Box>
                      )}
                    >
                      {Segments?.map((segment) => (
                        <MenuItem key={segment.id} value={segment.id}>
                          {segment.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={Boolean(errors?.segment?.message)}>
                      {errors?.segment?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
              )}
              <Divider />
              {/* ----------------------------------------------------- */}
              <Typography variant="h6">Schedule</Typography>
              <RHFRadioGroup
                name={'schedule'}
                options={[1, 2]}
                getOptionLabel={['Schedule Now', 'Schedule On']}
                row={true}
              />
              <FormControlLabel
                control={
                  <Checkbox checked={formValues.isReccuring} onChange={(e) => handleCheckboxChange(e)} name="checked" />
                }
                label="Is recurring?"
                name="isReccuring"
              />
              {formValues.isReccuring && (
                <TextField
                  label="Enter frequency"
                  type="number"
                  name="frequency"
                  value={formValues.frequency}
                  onChange={(e) => handleInputChange(e)}
                  inputProps={{ min: 1 }}
                  fullWidth
                  margin="normal"
                  error={Boolean(errors?.frequency)}
                  helperText={errors?.frequency?.message}
                />
              )}
              {/* <Divider /> */}
              {/* ----------------------------------------------------- */}
              {formValues.schedule === '2' && (
                <>
                  <Tooltip
                    title="Start date must be in the future or the current time and End date should be 30 mins from the start Date"
                    arrow
                    placement="right"
                  >
                    <InfoOutlined color="info" />
                  </Tooltip>
                  <RHFCustomDatePicker
                    name={'startDate'}
                    label={'start date'}
                    minDate={new Date()}
                    dateFormat={DATE_FORMATS.dateTime}
                  />
                </>
              )}
              {(formValues.isReccuring || formValues.schedule == '2') && (
                <RHFCustomDatePicker
                  name={'endDate'}
                  label={'end date'}
                  minDate={new Date()}
                  dateFormat={DATE_FORMATS.dateTime}
                />
              )}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Create Campaign' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
