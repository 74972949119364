import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import Scrollbar from '../../../components/Scrollbar';
import ChatMessageItem from './ChatMessageItem';
import whatAppBackgroungImage from '../../../../src/assets/agent_bg.png';
import { useDispatch, useSelector } from '../../../redux/store';
import { SkeletonConversationItem } from 'src/components/skeleton';
// ----------------------------------------------------------------------

ChatMessageList.propTypes = {
  conversation: PropTypes.array.isRequired,
};

export default function ChatMessageList({ conversation ,fileData,filePreview, uploadData}) {
  const scrollRef = useRef(null);

  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const loading = useSelector((state) => state.chat.isConversationLoading);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [conversation]);

  const imagesLightbox = conversation?.messages
    ?.filter((messages) => messages.contentType === 'image')
    ?.map((messages) => messages.body);

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };
  return (
    <>
      <Scrollbar
        scrollableNodeProps={{ ref: scrollRef }}
        sx={{
          p: 3,
          height: '100%',
          backgroundImage: `url(${whatAppBackgroungImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Box>
          {loading?<SkeletonConversationItem/>: conversation?.map((message, index) => (
            <ChatMessageItem
              key={index}
              message={message}
              conversation={conversation}
              onOpenLightbox={handleOpenLightbox}
              fileData={fileData}
              filePreview={filePreview} 
              uploadData={uploadData}
            />
          ))}
        </Box>
      </Scrollbar>

      {/* 
      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      /> 
      */}
    </>
  );
}
