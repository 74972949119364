import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useEffect, useMemo, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography, MenuItem, Divider } from '@mui/material';
import axios from '../../../utils/axios';
import { FormProvider,RHFTextField, RHFAutoComplete, RHFSelect  } from '../../../components/hook-form';
import { ApiEndpoints } from '../../../config';
import { ChannelContext } from '../../../contexts/ChannelsContext';

AddNewChannel.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function AddNewChannel({ isEdit, currentUser, toggleDrawer, channels }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const channelContext = useContext(ChannelContext)

  const newDepartmentSchema = Yup.object().shape({
    name: Yup.string().required('Channel Name is required'),
    channel_type: Yup.string().required('Select communication channel'),
    company_handle: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
    .required('Please Enter a valid Whatsapp Business mobile number'),
  });

  const defaultValues = useMemo(
    () => ({
      name: channelContext.selectedChannel?.is_edit? channelContext.selectedChannel?.name:  '',
      channel_type:  channelContext.selectedChannel?.is_edit? channelContext.selectedChannel?.channel_type: '',
      company_handle: channelContext.selectedChannel?.is_edit? channelContext.selectedChannel?.company_handle :'',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channelContext.selectedChannel]
  );
  const methods = useForm({
    resolver: yupResolver(newDepartmentSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors, },
  } = methods;

  // const [channels, setChannels] = useState([])

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser]);

  const createChannel = async (data, e) => {
    await axios.post(ApiEndpoints.CREATE_CHANNEL,data).then(res => {
        try {
            if(res.data.status === 200) {
              channelContext.getChannels()
              channelContext.toggleDrawer({open: false, is_edit: false})
            }
            enqueueSnackbar(res.data.message)
        } catch(error) {
          console.log(error)
          enqueueSnackbar(res.data.message);
        }
    });
  };

  const updateChannel = async (data, e) => {
    const id = channelContext.selectedChannel.channel_id
    await axios.post(ApiEndpoints.UPDATE_CHANNEL+id,data).then(res => {
        try {
          if(res.data.status === 200) {
            channelContext.getChannels()
            channelContext.toggleDrawer({open: false, is_edit: false})
            channelContext.clearChannel()
          } 
          enqueueSnackbar(res.data.message)
        } catch(error) {
          console.log(error)
          enqueueSnackbar(res.data.message)
        }
    });
  };

  const onSubmit = async (data, e) => {
    if(channelContext.selectedChannel.is_edit) {
      await updateChannel(data)
    } else {
      await createChannel(data,e)
      reset()
    }
    navigate('/app/general/channels');
  };


  // const getChannelTypes = async () => {
  //   const channels = await axios.get(ApiEndpoints.CREATE_CHANNEL)
  //   return channels
  // }
  // useEffect(() => {
  //   getChannelTypes().then(res => {
  //       const list = res.data?.data?.channelTypes
  //       const options = list.map(item => {
  //         return {
  //           label: item.name,
  //           value:item.id
  //         }
  //       })
  //       setChannels([...channels, ...options])
  //   })
  // }, [])

  console.log(errors)
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container width={500}>
        <Grid item md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography variant='h4'>{!channelContext.selectedChannel?.is_edit ? 'Create New Channel' : 'Save Changes'}</Typography>
              <RHFTextField name="name" label="Channel Name" />
              <RHFAutoComplete 
                options={channels}
                // value={defaultValues.channel_type}
                name="channel_type"
                label={"Select Communication Channel"}
                multiple={false}
              />
            <RHFTextField name="company_handle" label="Whatsapp Business Number" />

            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!Boolean(channelContext.selectedChannel?.is_edit) ? 'Create Channel' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
