import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Avatar, ListItemText, ListItemAvatar, ListItemButton, Badge } from '@mui/material';
//
import BadgeStatus from '../../../components/BadgeStatus';
import { alpha } from '@mui/material/styles';
import MyAvatar from '../../../components/MyAvatar';
import { formatTime } from './ChatMessageItem';

// ----------------------------------------------------------------------

const AVATAR_SIZE = 48;
const AVATAR_SIZE_GROUP = 32;

const RootStyle = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  transition: theme.transitions.create('all'),
}));

const AvatarWrapperStyle = styled('div')({
  position: 'relative',
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  '& .MuiAvatar-img': { borderRadius: '50%' },
  '& .MuiAvatar-root': { width: '100%', height: '100%' },
});

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'conversationStatus',
})(({ theme, conversationStatus }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: conversationStatus ? '#44b700' : theme.palette.error.main,
    color: conversationStatus ? '#44b700' : theme.palette.error.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

// ----------------------------------------------------------------------

const getDetails = (conversation, currentUserId) => {
  const otherParticipants = conversation.participants.filter((participant) => participant.id !== currentUserId);
  const displayNames = otherParticipants.reduce((names, participant) => [...names, participant.name], []).join(', ');
  let displayText = '';
  const lastMessage = conversation.messages[conversation.messages.length - 1];
  if (lastMessage) {
    const sender = lastMessage.senderId === currentUserId ? 'You: ' : '';
    const message = lastMessage.contentType === 'image' ? 'Sent a photo' : lastMessage.body;
    displayText = `${sender}${message}`;
  }
  return { otherParticipants, displayNames, displayText };
};

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return "";
  const countryCode = phoneNumber.slice(0, 2); 
  const mainNumber = phoneNumber.slice(2);     
  return `+${countryCode} ${mainNumber}`;
}

export const getInitials = (name) => {
  const words = name?.trim()?.split(' ');
  if (words?.length === 1) {
    return words?.[0]?.[0]?.toUpperCase();
  }
  return (words?.[0]?.[0] + words?.[words?.length - 1]?.[0])?.toUpperCase();
};

ChatConversationItem.propTypes = {
  isSelected: PropTypes.bool,
  conversation: PropTypes.string.isRequired,
  isOpenSidebar: PropTypes.bool,
  onSelectConversation: PropTypes.func,
  conversationStatus: PropTypes.bool,
};

export default function ChatConversationItem({
  isSelected,
  conversation,
  isOpenSidebar,
  onSelectConversation,
  conversationStatus,
  conversationType,
  conversationInfo,
  converstaionTime,
  conversationPhone
}) {


  const oldconversation = conversation

  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
  const name = userDetails?.data?.user?.organization?.contact_person;

  const avatarName = getInitials(conversationInfo?.customer_name?conversationInfo?.customer_name:name);

  return (
    <RootStyle
      onClick={onSelectConversation}
      sx={{
        ...(isSelected && { bgcolor: '#E5F3FF' }),
      }}
    >
      <ListItemAvatar>
        <Box
          sx={{
            ...(true && {
              position: 'relative',
              width: AVATAR_SIZE,
              height: AVATAR_SIZE,
              '& .avatarWrapper': {
                position: 'absolute',
                width: AVATAR_SIZE_GROUP,
                height: AVATAR_SIZE_GROUP,
                '&:nth-of-type(1)': {
                  left: 0,
                  zIndex: 9,
                  bottom: 5,
                  '& .MuiAvatar-root': {
                    border: (theme) => `solid 2px ${theme.palette.background.paper}`,
                  },
                },
                '&:nth-of-type(2)': { top: 2, right: 0 },
              },
            }),
          }}
        >
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            conversationStatus={conversationStatus}
          >

            {conversationInfo?.customer_name?<Avatar sx={{ bgcolor: alpha('#000', 0.1) }}>{avatarName?avatarName:""}</Avatar>: <MyAvatar />}
          </StyledBadge>
        </Box>
      </ListItemAvatar>

      {isOpenSidebar && (
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <ListItemText
            primary={conversationInfo?.customer_name?conversationInfo?.customer_name:formatPhoneNumber(conversationPhone)}
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
            }}
            secondaryTypographyProps={{
              noWrap: true,
              variant: true ? 'subtitle2' : 'body2',
              color: true ? 'textPrimary' : 'textSecondary',
            }}
          />
          <Box
            sx={{
              mb: 1.25,
              fontSize: 12,
              lineHeight: '22px',
              whiteSpace: 'nowrap',
              color: 'text.disabled',
            }}
          >
            {conversation ? conversation :oldconversation}
          </Box>
        </Box>
      <Box sx={{
          display: 'flex',
        }} >
        <Box
          sx={{
            mb: 1.25,
            fontSize: 12,
            lineHeight: '22px',
            whiteSpace: 'nowrap',
            color: 'text.disabled',
          }}
        >
          {converstaionTime? converstaionTime:'02 sep'}
        </Box>
        <Box sx={{ml:1}}>
        <svg stroke="currentColor" fill="#22c35e" stroke-width="0" viewBox="0 0 512 512" focusable="false" class="chakra-icon css-fhs5hy" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path d="M260.062 32C138.605 32 40.134 129.701 40.134 250.232c0 41.23 11.532 79.79 31.559 112.687L32 480l121.764-38.682c31.508 17.285 67.745 27.146 106.298 27.146C381.535 468.464 480 370.749 480 250.232 480 129.701 381.535 32 260.062 32zm109.362 301.11c-5.174 12.827-28.574 24.533-38.899 25.072-10.314.547-10.608 7.994-66.84-16.434-56.225-24.434-90.052-83.844-92.719-87.67-2.669-3.812-21.78-31.047-20.749-58.455 1.038-27.413 16.047-40.346 21.404-45.725 5.351-5.387 11.486-6.352 15.232-6.413 4.428-.072 7.296-.132 10.573-.011 3.274.124 8.192-.685 12.45 10.639 4.256 11.323 14.443 39.153 15.746 41.989 1.302 2.839 2.108 6.126.102 9.771-2.012 3.653-3.042 5.935-5.961 9.083-2.935 3.148-6.174 7.042-8.792 9.449-2.92 2.665-5.97 5.572-2.9 11.269 3.068 5.693 13.653 24.356 29.779 39.736 20.725 19.771 38.598 26.329 44.098 29.317 5.515 3.004 8.806 2.67 12.226-.929 3.404-3.599 14.639-15.746 18.596-21.169 3.955-5.438 7.661-4.373 12.742-2.329 5.078 2.052 32.157 16.556 37.673 19.551 5.51 2.989 9.193 4.529 10.51 6.9 1.317 2.38.901 13.531-4.271 26.359z"></path>
        </svg>
        </Box>
      </Box>
        {/* {isUnread && <BadgeStatus status="unread" size="small" />} */}
      </Box>
      
      )}
    </RootStyle>
  );
}
