import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CreateContactSubmodal
 = ({ open, handleClose }) => {
  const [selectedNumber, setSelectedNumber] = useState('Demo number');

  const handleChange = (event) => {
    setSelectedNumber(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Create Contact
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          required
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Phone Number"
          variant="outlined"
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                +91
              </InputAdornment>
            ),
          }}
          sx={{ mb: 2 }}
        />
        <Select
          fullWidth
          value={selectedNumber}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
        >
          <MenuItem value="Demo number">Demo number</MenuItem>
        </Select>
        <Button
          variant="contained"
          fullWidth
          sx={{
            bgcolor: '#008AD2',
            '&:hover': {
              bgcolor: '#0077C2',
            },
            height: 48,
            borderRadius: 2,
          }}
        >
          Start Conversation
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateContactSubmodal
;
