import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  MenuItem,
  Divider,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { ApiEndpoints } from '../../../config';
import { PATH_APP } from '../../../routes/paths';

import { FormProvider, RHFCheckbox, RHFSelect, RHFTextField, RHFAutoComplete } from '../../../components/hook-form';

import axios from '../../../utils/axios';
import { de } from 'date-fns/locale';

AddNewDepartment.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function AddNewDepartment({ isEdit, currentUser, toggleDrawer, isDrawerOpen, fetchDepartments }) {
  const navigate = useNavigate();
  // const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const typeofIsEdit = Boolean(isEdit);
  const [formData, setFormData] = useState();
  const [departmentData, setDepartmentData] = useState({
    departmentName: '',
    communicationChannels: [],
    agentChatAssignmentRule: [],
    templateApproval: false,
    incomingMediaSupport: false,
    auditor: [],
  });

  const newDepartmentSchema = Yup.object().shape({
    departmentName: Yup.string().required('Department Name is required'),
    departmentDescription: Yup.string().required('Department Description is required'),
    // communicationChannel: Yup.array()
    //   .of(Yup.string().required('Select at least one communication channel'))
    //   .min(1, 'Select at least one communication channel')
    //   .required('Select at least one communication channel'),
    // agentChatAssignmentRule: Yup.string().required('Agent Chat Assignment Rule is Required'),
  });

  const defaultValues = useMemo(
    () => ({
      departmentName: typeofIsEdit && formData ? formData?.name : '',
      // communicationChannel:
      //   typeofIsEdit && formData && departmentData
      //     ? departmentData?.communicationChannels
      //     .filter((item) => formData?.channel_ids.includes(item.value))
      //     .map((channel) => ({ label: channel.label, value: channel.value }))
      //     : [],
      // agentChatAssignmentRule: typeofIsEdit && formData ? formData?.agent_chat_assignment_type : '',
      // agentPerformanceField: typeofIsEdit && formData ? formData?.agent_performance : '',
      // templateApproval: typeofIsEdit && formData ? formData?.template_approval_required : '',
      // incomingMediaSupport: typeofIsEdit && formData ? formData?.incoming_media_supported : '',
      // auditors: typeofIsEdit && formData ? formData?.auditor : '',
    }),
    [formData]
  );

  const methods = useForm({
    resolver: yupResolver(newDepartmentSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    enableReinitialize,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();
  useEffect(() => {
    if (isEdit) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit,formData]);

  const fetchData = async () => {
    // Fetch common dropdown data required for both add and edit
    try {
      const response = await axios.get(ApiEndpoints.DEPARTMENTS); // Endpoint to get dropdown data
      const commonData = response.data.data;

      const departmentOptions = commonData.map((department) => ({
        label: department.name,
        value: department.id,
      }));

      // setDepartmentData({
      //   ...departmentData,
      //   communicationChannels: commonData.departmentChannels.map((channel) => ({
      //     label: channel.name,
      //     value: channel.id,
      //   })),
      //   agentChatAssignmentRule: commonData.agentChatAssignmentType,
      //   auditor: commonData.auditors,
      //   agentPerformance: commonData.agentPerformance,
      // });
      setDepartmentData((prevState) => ({
        ...prevState,
        communicationChannels: departmentOptions,
        agentChatAssignmentRule: [],
        auditor: [],
        agentPerformance: [],
      }));
    } catch (err) {
      enqueueSnackbar('Failed to fetch common data', { variant: 'error' });
    }

    // Additional fetching for edit mode
    if (isEdit?.id) {
      try {
        const editResponse = await axios.put(`${ApiEndpoints.DEPARTMENTS}/${isEdit?.id}`);
        const fetchedData = editResponse.data.data;
        setFormData(fetchedData?.department);
      } catch (err) {
        console.error('Failed to fetch data for edit:', err);
        enqueueSnackbar('Failed to fetch edit data', { variant: 'error' });
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   // Reset form with new default values when departmentData changes
  //   methods.reset(departmentData);
  // }, [departmentData,formData]);
  const formValues = getValues();

  const onSubmit = async (data, e) => {
    const payload = {
      name: data.departmentName,
      description: data.departmentDescription,
      // agent_performance: data.agentPerformanceField,
      // channel_ids: data.communicationChannel?.map((channel) => channel.value),
      // agent_chat_assignment_type: data.agentChatAssignmentRule,
      // template_approval_required: data.templateApproval,
      // incoming_media_supported: data.incomingMediaSupport,
      // auditor: Number(data.auditors),
    };
    const url = isEdit && isEdit?.id ? ApiEndpoints.DEPARTMENTS + '/' + isEdit.id : ApiEndpoints.DEPARTMENTS;

    await axios
      .post(url, payload)
      .then((response) => {
        if (response.data.status === 200) {
          fetchDepartments();
          toggleDrawer();
          enqueueSnackbar(response.data.message, { variant: 'success' });
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container width={500}>
        <Grid item md={12}>
          <Card sx={{ p: 3 ,height: '100%'}} >
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography variant="h4">{!isEdit ? 'Add New Department' : 'Save Changes'}</Typography>
              <RHFTextField name="departmentName" label="Department Name" />
              <RHFTextField name="departmentDescription" label="Department Description" />
              {/* <Controller
                name="communicationChannel"
                control={methods.control}
                rules={{ required: 'Select at least one communication channel' }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    multiple
                    options={departmentData.communicationChannels}
                    getOptionLabel={(option) => option.label}
                    defaultValue={field.value}
                    value={formValues?.communicationChannel}
                    onChange={(event, newValue) => field.onChange(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Communication Channel(s)"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                      />
                    )}
                  />
                )}
              /> */}
              {/* <RHFSelect
                name="agentChatAssignmentRule"
                label="Agent Chat Assignment Rule"
                size="large"
                InputLabelProps={{ shrink: true }}
                value={formValues?.agentChatAssignmentRule}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                sx={{ maxWidth: { md: '100%' } }}
              >
                {departmentData.agentChatAssignmentRule.map((type) => (
                  <MenuItem
                    key={type}
                    value={type}
                    sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2', textTransform: 'capitalize' }}
                  >
                    {type}
                  </MenuItem>
                ))}
              </RHFSelect>
              {formValues.agentChatAssignmentRule === 'AGENT_PERFORMANCE' && (
                <RHFSelect
                  name="agentPerformanceField"
                  label="Agent Performance"
                  size="large"
                  InputLabelProps={{ shrink: true }}
                  value={formValues?.agentPerformanceField}
                  SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  sx={{ maxWidth: { md: '100%' } }}
                >
                  {departmentData?.agentPerformance?.map((type) => (
                    <MenuItem
                      key={type}
                      value={type}
                      sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2', textTransform: 'capitalize' }}
                    >
                      {type}
                    </MenuItem>
                  ))}
                </RHFSelect>
              )}
              <RHFCheckbox name={'templateApproval'} label={'Template Approval Required'} /> */}
              {/* <RHFSelect
                name="auditors"
                label="Select Auditor"
                size="large"
                InputLabelProps={{ shrink: true }}
                value={formValues?.auditors}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                sx={{ maxWidth: { md: '100%' } }}
              >
                {departmentData.auditor.map((auditor) => (
                  <MenuItem
                    key={auditor.id}
                    value={auditor.id}
                    sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2', textTransform: 'capitalize' }}
                  >
                    {auditor.username}
                  </MenuItem>
                ))}
              </RHFSelect> */}
              {/* <RHFCheckbox name={'incomingMediaSupport'} label={'Incoming media supported'} /> */}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Create Department' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
