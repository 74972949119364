import React from 'react';
import * as Yup from 'yup';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { FieldArray, Form, Formik } from 'formik';
import FeedIcon from '@mui/icons-material/Feed';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import { getContacts, setConversationsSuccess, setContactsSuccess,clearContacts } from '../../../redux/slices/chat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import InputAdornment from '@mui/material/InputAdornment';

import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  Divider,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  Chip,
  Checkbox,
  FormGroup,
  FormControlLabel,
  MenuItem,
  FormHelperText,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
  Icon,
} from '@mui/material';
import { ApiEndpoints, DATE_FORMATS } from '../../../config';
import axiosInstance from '../../../utils/axios';

// redux
import { useDispatch, useSelector } from '../../../redux/store';

const ChatProfileEdit = ({ onCLickEditHandler }) => {
  const [department, setDepartment] = useState([]);
  const dispatch = useDispatch();
  const { activeConversationId } = useSelector((state) => state.chat);
  const [group, setGroup] = useState([]);
  const [moreInfo, setMoreInfo] = useState(false);
  const [contactDetailsExpanded, setContactDetailsExpanded] = useState(true);
  const [additionalDetailsExpanded, setAdditionalDetailsExpanded] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const userData = useSelector((state) => state?.auth?.user?.data);
  const userId = userData?.user?.native_id;

  console.log(activeConversationId, 'activeid&&&&');

  const NewUserSchema = Yup.object().shape({
    marketOptIn: Yup.string(),
    userName: Yup.string()
      .matches(/^[A-Za-z]+$/, 'User Name must only contain alphabets'),
    userMobile: Yup.string()
      .matches(/^[0-9]*$/, 'Mobile Number must only contain numbers'),
    userEmail: Yup.string().email('Invalid email'),
    userHandle: Yup.string()
      .matches(/^[A-Za-z]+$/, 'Handle must only contain alphabets'),
    department: Yup.string().required('Please select a department'),
    group: Yup.array().of(Yup.string()).nullable(),
    moreInfo: Yup.array().of(
      Yup.object().shape({
        key: Yup.string()
          .nullable()
          .test('key-required-if-description', 'Key is required', function (value) {
            const { description } = this.parent;
            if (!value && description) {
              return false;
            }
            return true;
          }),
        description: Yup.string()
          .nullable()
          .test('description-required-if-key', 'Description is required', function (value) {
            const { key } = this.parent;
            if (!value && key) {
              return false;
            }
            return true;
          }),
      })
    ).nullable(),
  });
  
  

  const fetchedTags = activeConversationId?.tags
    ? Object.entries(activeConversationId?.tags).reduce((acc, [key, value]) => {
        acc.push({ key, value });
        return acc;
      }, [])
    : [];

  const defaultValues = {
    marketOptIn: activeConversationId?.customerInfo?.market_opt_in ? 'Yes' : 'No',
    userName: activeConversationId?.customerInfo?.customer_name ? activeConversationId?.customerInfo?.customer_name : '',
    userMobile: activeConversationId?.customerPhone?.substring(2) ? activeConversationId?.customerPhone?.substring(2) : '',
    userEmail: activeConversationId?.customerInfo?.customer_email ? activeConversationId?.customerInfo?.customer_email : '',
    userHandle: activeConversationId?.customerInfo?.customer_handle ? activeConversationId?.customerInfo?.customer_handle : '',
    department: activeConversationId?.customerInfo?.department?.id ? activeConversationId?.customerInfo?.department?.id : '',
    group: activeConversationId?.customerInfo?.group ? activeConversationId?.customerInfo?.group?.id : [],
    moreInfo: activeConversationId?.tags
      ? assignRuleTriggers(fetchedTags)
      : [
          {
            key: '',
            description: '',
          },
        ],
  };

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
  } = methods;

  const getUserChannels = () => {
    axiosInstance
      .get(ApiEndpoints.DEPARTMENTS)
      .then((response) => {
        setDepartment(response?.data?.data || []);
      })
      .catch((error) => {
        console.log(`error`, { error });
      });
  };

  const getGroups = () => {
    axiosInstance
      .get(ApiEndpoints.CRM_GROUP_LIST)
      .then((res) => {
        setGroup(res?.data?.data?.data);
      })
      .catch((error) => {
        console.log(`error`, { error });
      });
  };

  useEffect(() => {
    getUserChannels();
    getGroups();
  }, []);

  const submitHandler = async (data, e) => {
    const payload = {
        customer_email: data?.userEmail,
        customer_handle: data?.userHandle,
        customer_mobile: `+91${data?.userMobile}`,
        customer_name: data?.userName,
        organisation_department_id: parseInt(data?.department),
        group_ids:data?.group,
        market_opt_in:data?.marketOptIn=== 'Yes',
        tags: data?.moreInfo.reduce((acc, info) => {
          if (info.key) { 
            acc[info.key] = info.description;
          }
          return acc;
        }, {}),
      };
    console.log(payload,'condataa')
    await axiosInstance
    .post(ApiEndpoints.CHAT_CUSTOMER_UPDATE, payload).then((res) => {
        if (res?.data?.status === 200) {
          reset();
          onCLickEditHandler()
          dispatch(getContacts(userId,ApiEndpoints.GET_ALL_CHAT_CONTACTS));
          enqueueSnackbar('Update success!', { variant: 'success' });
        } else {
          enqueueSnackbar(response?.data?.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        let errorMessage = error?.message || '';

        errorMessage = errorMessage.replace(/Customer/gi, 'User');
        enqueueSnackbar(
          <div>
            <span>{errorMessage}</span>
            <br />
            <span>{`Failed to update user!`}</span>
          </div>,
          { variant: 'error' }
        );
        console.error(error);
      });
  };

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={NewUserSchema}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={(values) => {
        submitHandler(values);
      }}
    >
      {({ values, errors, setFieldValue, setFieldTouched, setFieldError, setValues,resetForm }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <ArrowBackIcon sx={{ fontSize: '15px', cursor: 'pointer' }} onClick={onCLickEditHandler} />
                    <Typography gutterBottom sx={{ mb: 0, fontWeight: 400, color: 'gray' }}>
                      Edit Contact
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Button variant="outlined" size="small" sx={{ mr: 1 }} onClick={()=>{
                      console.log('reset***********')
                      resetForm()}}>
                      Reset
                    </Button>
                    <Button type="submit" variant="contained" loading={isSubmitting} size="small" color="primary">
                      Save
                    </Button>
                  </Box>
                </Box>
                <Divider sx={{ mt: 1 }} />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: '#f5f9fc',
                    p: 1,
                    borderRadius: '8px',
                    border: '1px solid #e0e0e0',
                    mb:2
                  }}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Marketing Opt-In 
                    </Typography>
                  </Box>
                  <Typography color="textSecondary" mb={1} sx={{  fontSize: '12px' }}>
                    Mark "YES" if consent for marketing messages has been obtained from this contact.
                  </Typography>
                  <FormControl fullWidth error={Boolean(errors.marketOptIn)}>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.marketOptIn}
                      onChange={(event, newValue) => setFieldValue('marketOptIn', event.target.value)}
                      variant="outlined"
                      sx={{
                        backgroundColor: '#ffffff',  
                        fontSize: '12px',            
                        padding: '5px',        
                        '.MuiOutlinedInput-root': {
                        //   padding: '5px',       
                        },
                        '.MuiSelect-select': {
                          padding: '5px',
                        },
                      }}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                    <FormHelperText error={Boolean(errors?.marketOptIn?.message)}>
                            {errors?.marketOptIn?.message}
                          </FormHelperText>
                  </FormControl>
                </Box>
                <Divider sx={{ mt: 2, mb:2 }} />
                <Accordion
                expanded={contactDetailsExpanded}
                onChange={() => setContactDetailsExpanded(!contactDetailsExpanded)}
                  sx={{
                    boxShadow: 'none',
                    borderRadius: 0,
                    overflow: 'hidden',
                    '& .MuiAccordionSummary-content': {
                      margin: 0,
                      padding: 0,
                    },
                    '& .MuiAccordionDetails-root': {
                      padding: 0,
                    },
                    '&.Mui-expanded': {
                      boxShadow: 'none',
                      borderRadius: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      minHeight: '30px',
                      height: '30px',
                      padding: '0px',
                      '& .MuiAccordionSummary-content': {
                        margin: 0,
                        padding: 0,
                      },
                      '&.Mui-expanded': {
                        minHeight: '30px',
                        height: '30px',
                        '& .MuiAccordionSummary-content': {
                          margin: 0,
                          padding: 0,
                        },
                      },
                    }}
                  >
                    <Box sx={{ gap: 0.5, display: 'flex', alignItems: 'center' }}>
                      <PermContactCalendarOutlinedIcon sx={{ color: 'gray' }} fontSize="12px" />
                      <Typography sx={{ fontWeight: 700 }}>Contact Details</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: '0 !important',
                    }}
                  >
                    <Stack spacing={1}>
                      <Stack>
                        <Typography sx={{ mb: 0.5 }}>Name</Typography>
                        <TextField
                          size="small"
                          name="userName"
                          value={values.userName}
                          error={Boolean(errors.userName)}
                          helperText={errors.userName}
                          onChange={(e) => setFieldValue('userName', e.target.value)}
                        />
                      </Stack>
                      <Stack>
                        <Typography sx={{ mb: 0.5 }}>Mobile</Typography>
                        <TextField
                          size="small"
                          disabled
                          name="userMobile"
                          value={values.userMobile}
                          error={Boolean(errors.userMobile)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                        }}
                          helperText={errors.userMobile}
                          onChange={(e) => setFieldValue('userMobile', e.target.value)}
                        />
                      </Stack>
                      <Stack>
                        <Typography sx={{ mb: 0.5 }}>Handle</Typography>
                        <TextField
                          size="small"
                          name="userHandle"
                          value={values.userHandle}
                          error={Boolean(errors.userHandle)}
                          helperText={errors.userHandle}
                          onChange={(e) => setFieldValue('userHandle', e.target.value)}
                        />
                      </Stack>
                      <Stack>
                        <Typography sx={{ mb: 0.5 }}>Email</Typography>
                        <TextField
                          size="small"
                          name="userEmail"
                          value={values.userEmail}
                          error={Boolean(errors.userEmail)}
                          helperText={errors.userEmail}
                          onChange={(e) => setFieldValue('userEmail', e.target.value)}
                        />
                      </Stack>
                      <Box>
                        <FormControl fullWidth error={Boolean(errors.department)}>
                          <Typography sx={{ mb: 0.5 }}>Department</Typography>
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="department"
                            value={values.department || ''}
                            onChange={(event, newValue) => setFieldValue('department', event.target.value)}
                          >
                            {department.map((department) => (
                              <MenuItem key={department.id} value={department.id}>
                                {department?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error={Boolean(errors?.department?.message)}>
                            {errors?.department?.message}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl fullWidth error={Boolean(errors.group)}>
                          <Typography>Group</Typography>
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            multiple
                            name="group"
                            value={values.group || []}
                            onChange={(event, newValue) => setFieldValue('group', event.target.value)}
                          >
                            {group.map((group) => (
                              <MenuItem key={group.id} value={group.id}>
                                {group.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error={Boolean(errors?.group?.message)}>
                            {errors?.group?.message}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <Divider sx={{ mt: 2, mb:2 }} />
                <Accordion
                expanded={additionalDetailsExpanded}
                onChange={() => setAdditionalDetailsExpanded(!additionalDetailsExpanded)}
                  sx={{
                    boxShadow: 'none',
                    borderRadius: 0,
                    overflow: 'hidden',
                    '& .MuiAccordionSummary-content': {
                      margin: 0,
                      padding: 0,
                    },
                    '& .MuiAccordionDetails-root': {
                      padding: 0,
                    },
                    '&.Mui-expanded': {
                      boxShadow: 'none',
                      borderRadius: 0,
                    },
                  }}
                >
                 <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      minHeight: '30px',
                      height: '30px',
                      padding: '0px',
                      '& .MuiAccordionSummary-content': {
                        margin: 0,
                        padding: 0,
                      },
                      '&.Mui-expanded': {
                        minHeight: '30px',
                        height: '30px',
                        '& .MuiAccordionSummary-content': {
                          margin: 0,
                          padding: 0,
                        },
                      },
                    }}
                  >
                    <Box sx={{ gap: 0.5, display: 'flex', alignItems: 'center' }}>
                      <FeedIcon sx={{ color: 'gray' }} fontSize="12px" />
                      <Typography sx={{ fontWeight: 700 }}>Additional Details</Typography>
                    </Box>
                  </AccordionSummary>   
                  <AccordionDetails
                    sx={{
                      padding: '0 !important',
                    }}
                  >
                     {!moreInfo || values.moreInfo.length === 0 ? (
                      <LoadingButton
                        sx={{fontSize:'12px',mt:3}}
                        type="button"
                        variant="contained"
                        onClick={() => {
                          setMoreInfo(true);
                          setFieldValue('moreInfo', [{ key: '', description: '' }]);
                        }}
                        style={{ width: '100px' }}
                      >
                        + Add Info
                      </LoadingButton>
                    ) : (
                        <Typography sx={{mb:1, mt:2}}>More Info</Typography>
                    )}
                    {moreInfo && (
                      <FieldArray
                        name="moreInfo"
                        render={(arrayHelpers) => (
                          <>
                            {values.moreInfo.map((item, index) => (
                              <div key={index} style={{ display: 'block' }}>
                                <FormControl fullWidth error={Boolean(errors.moreInfo?.[index]?.key)}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ position: 'relative', '&:hover .delete-icon': { visibility: 'visible' }}}
                                  > 
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{fontSize:'12px', height:'20px' }}
                                  >
                                    <TextField
                                      value={item.key}
                                      onChange={(event) => setFieldValue(`moreInfo[${index}].key`, event.target.value)}
                                      fullWidth
                                      sx={{ mr: 2, width: 'calc(50% - 8px)', height: '64px'}}
                                      size="small"
                                      placeholder="Key"
                                      error={Boolean(errors.moreInfo?.[index]?.key)}
                                      helperText={errors.moreInfo?.[index]?.key}
                                    />
                                    <TextField
                                    value={item.description}
                                    onChange={(event) => setFieldValue(`moreInfo[${index}].description`, event.target.value)}
                                    fullWidth
                                    sx={{ width: 'calc(50% - 8px)', height: '64px' }} 
                                    size="small"
                                    placeholder="Description"
                                    error={Boolean(errors.moreInfo?.[index]?.description)}
                                    helperText={errors.moreInfo?.[index]?.description}
                                  />
                                  </Stack>
                                    <DeleteOutlineOutlinedIcon
                                      className="delete-icon"
                                      sx={{
                                        visibility: 'hidden',
                                        color: 'red',
                                        cursor: 'pointer',
                                        mb:5,
                                        ml:1
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </Stack>
                                  
                                </FormControl>
                                {index < values.moreInfo.length - 1 && (
                                  <Typography
                                    sx={{
                                      mb:2,
                                      backgroundColor: '#E9EAEC',
                                      width: '55px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      borderRadius: 1,
                                    }}
                                  >
                                    AND
                                  </Typography>
                                )}
                                {index === values.moreInfo.length - 1 && (
                                  <LoadingButton
                                  sx={{fontSize:'12px'}}
                                    type="button"
                                    variant="contained"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        key: '',
                                        description: '',
                                      })
                                    }
                                  >
                                    + Add Info
                                  </LoadingButton>
                                )}
                              </div>
                            ))}
                          </>
                        )}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
                <Divider sx={{ mt: 2, mb:2 }} />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChatProfileEdit;
