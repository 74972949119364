import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '12px',
  boxShadow: 24,
  p: 4,
};

export default function CampaignModels({ open, handleClose, data, fetchedData, type }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography align="center" variant="h6" gutterBottom>
          Campaign Details
        </Typography>
        <Typography variant="subtitle1">Campaign Name: {data.name}</Typography>
        <Typography variant="subtitle1" sx={{mb:'1rem'}}>Description: {data.description}</Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchedData?.length > 0 ? (
                fetchedData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="left"
                      
                    >
                      <Typography>{item}</Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>No {type} available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
}

CampaignModels.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  fetchedData: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired, 
};
